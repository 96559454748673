<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-1">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center grey lighten-3 pa-0 pl-0 pt-0 mt-0"
        id="login"
      >
        <v-flex xs12 class="">
          <p class="headline">
            <v-img style="border-radius: 4px" contain src="../assets/mm.jpg">
            </v-img>
          </p>
        </v-flex>
        <!-- <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="ስም"
            required
            dense
            outlined
            prepend-icon="person"
            :hide-details="true"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="የይለፍ ቃል"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
            :hide-details="true"
          ></v-text-field>
        </v-flex>

        <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
          <v-btn
            @keyup.enter="cashierLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="cashierLoginLoading"
          >
            <span class="text-capitalize">ግባ</span>
          </v-btn>
        </v-flex> -->
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    currentLocation: "",
    ipAddress: "",
    cashierLoginLoading: false,
    user: null,
  }),

  methods: {
    async login() {
      this.$store.dispatch("loginCashier", "");
      if (this.user.id.toString() == "6160879304") {
        this.$store.dispatch("loginCashier", this.user.id.toString());
        this.$router.push({ name: "home" });
      } else alert("You do not have access!");
    },
  },

  async mounted() {
    if (window.Telegram.WebApp) {
      this.user = await window.Telegram.WebApp.initDataUnsafe.user;
      await window.Telegram.WebApp.expand(); // Request full-screen mode

      this.login();
    }
  },
  async created() {},
};
</script>
<style scoped>
#login {
  max-width: 370px;
  margin: auto;
  border-radius: 5px;
}
</style>
