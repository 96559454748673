<template>
  <v-app>
    <v-main  id="body">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style scoped>

@media print {
  body {
    margin: -2rem 0 0 0;
  }
}
</style>
