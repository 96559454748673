<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="$store.state.cashier != ''">
      <v-app-bar app elevation="0" dark height="auto" class="purple">
        <span class="pl-4">የቀሲስ ዮናስ ንስሀ ልጆች</span>
        <v-spacer></v-spacer>

        <v-btn x-small text @click="logout">
          <span class="mr-2 text-capitalize"
            ><v-icon>arrow_forward</v-icon></span
          >
        </v-btn>
      </v-app-bar>
      <div class="pl-2 pr-2 pt-10" v-if="Date.now() > 0">
        <div class="text-center" v-if="pageLoading">
          <v-progress-circular
            :size="70"
            :width="2"
            color="grey"
            indeterminate
          ></v-progress-circular>
        </div>
        <div class="pa-1 pt-3" v-if="!pageLoading">
          <v-layout row wrap>
            <v-flex xs12>
              <v-layout row wrap>
                <v-flex xs8 class="text-left pl-4">
                  <strong>ጠቅላላ የከፈለ {{ ljoch.length - notPaid }} ሰው</strong
                  ><br />
                  <strong>የዛሬ {{ todayAmount }} ብር</strong><br />
                  <strong>የባለፈው {{ totalAmount - todayAmount }} ብር</strong
                  ><br />
                  <strong>ጠቅላላ {{ totalAmount }} ብር</strong>

                  <br /><br />
                  <span>ባለፈው አመት ከፍለው አሁን ያልከፈሉ {{ notPaid }} ሰው</span>
                  <br /><br />

                  <span v-for="fre in frequency" :key="fre.amount">
                    <span v-if="fre.amount != 0">
                      ባለ {{ fre.amount }} ብር&nbsp;&nbsp;&nbsp;&nbsp;{{
                        fre.frequency
                      }}&nbsp;ሰው<br />
                    </span>
                  </span>
                </v-flex>

                <v-flex xs4 class="text-center">
                  <v-btn text @click="printList">
                    <strong class="text-capitalize">
                      <v-icon>print</v-icon>
                    </strong>
                  </v-btn>
                  <br /><br /><br />
                  <v-btn
                    small
                    text
                    outlined
                    class="green mt-4"
                    @click="
                      addDialog = true;
                      user_id = '';
                    "
                  >
                    <strong class="text-capitalize white--text"> መዝግብ </strong>
                  </v-btn>
                  <br /><br />
                  <v-btn small text outlined class="blue mt-4" @click="listAll">
                    <strong class="text-capitalize white--text">
                      ሁሉም አሳይ
                    </strong>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout row wrap class="mb-15 pa-1 pt-4" style="font-size: 16px">
            <v-flex md4 xs12 class="pa-3">
              <v-text-field
                style="font-size: 11px"
                v-model="searched"
                label="ፈልግ"
                required
                dense
                @change="getSearched"
                outlined
                append-icon="search"
                :hide-details="true"
              ></v-text-field>
            </v-flex>

            <horizontal-scroll
              class="horizontal-scroll white pa-3 pl-0 pt-0"
              id="report"
            >
              <table style="width: 100%" class="ma-0 mt-0">
                <tr>
                  <th>ተ/ቁ</th>
                  <th>የክርስትና ስም</th>
                  <th>አለማዊ ስም</th>
                  <th>ስልክ ቁጥር</th>

                  <th>የብር መጠን</th>
                  <th></th>
                </tr>
                <tr v-for="(cashier, i) in filteredLjoch" :key="i">
                  <td>
                    {{ i + 1 }}
                  </td>
                  <td>
                    {{ cashier.ksm }}
                  </td>
                  <td>
                    {{ cashier.asm }}
                  </td>
                  <td>
                    <a :href="`tel:${cashier.phone}`">{{ cashier.phone }}</a>
                  </td>
                  <td>
                    {{ cashier.amount }}
                  </td>
                  <td>
                    <v-btn small text @click="renew(cashier)" class="mt-1">
                      <v-icon class="green--text">attach_money</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      text
                      @click="editClicked(cashier)"
                      class="mt-1"
                    >
                      <v-icon class="blue--text">edit</v-icon>
                    </v-btn>

                    <v-btn
                      small
                      text
                      @click="deleteCashier(cashier.user_id)"
                      class="mt-1"
                    >
                      <v-icon class="red--text">delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </horizontal-scroll>
          </v-layout>

          <v-dialog v-model="addDialog" persistent max-width="420">
            <v-card class="text-center">
              <v-card-title
                >የሚከተለውን ቅጽ ይሙሉ <v-spacer></v-spacer>
                <v-btn
                  class="text-capitalize"
                  dark
                  color="red"
                  text
                  @click="addDialog = false"
                >
                  ዝጋ
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
                  <v-text-field
                    style="font-size: 11px"
                    v-model="ksm"
                    :rules="[(v) => !!v || 'Required']"
                    label="የክርስትና ስም"
                    required
                    dense
                    outlined
                    prepend-icon="group"
                    :hide-details="true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
                  <v-text-field
                    style="font-size: 11px"
                    v-model="asm"
                    :rules="[(v) => !!v || 'Required']"
                    label="አለማዊ ስም"
                    required
                    dense
                    outlined
                    prepend-icon="person"
                    :hide-details="true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
                  <v-text-field
                    style="font-size: 11px"
                    v-model="phone"
                    :rules="[(v) => !!v || 'Required']"
                    label="ስልክ ቁጥር"
                    required
                    dense
                    outlined
                    prepend-icon="phone"
                    :hide-details="true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
                  <v-text-field
                    style="font-size: 11px"
                    v-model="amount"
                    :rules="[(v) => !!v || 'Required']"
                    label="የብር መጠን"
                    required
                    dense
                    outlined
                    prepend-icon="money"
                    :hide-details="true"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 class="mt-1 pl-5 pa-2 pr-5">
                  <v-btn
                    type="submit"
                    dark
                    @click="addLj"
                    small
                    text
                    class="primary"
                    id="loginBtn"
                    :loading="regLoading"
                  >
                    <span class="text-capitalize">መዝግብ</span>
                  </v-btn>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-dialog>

          <v-dialog v-model="printDialog" persistent max-width="1000">
            <v-card class="">
              <v-card-title> Print Page </v-card-title>
              <v-form @submit.prevent="">
                <v-card-text
                  ref="printTbl"
                  style="font-weight: bold; padding: 2px"
                >
                  <table style="width: 100%">
                    <caption>
                      <strong><u>የቀሲስ ዮናስ ንስሀ ልጆች ፡ የጽጌ መዋጮ</u> </strong>
                    </caption>
                    <tr>
                      <th style="text-align: left">ተ/ቁ</th>
                      <th style="text-align: left">የክርስትና ስም</th>
                      <th style="text-align: left">አለማዊ ስም</th>
                      <th style="text-align: left">ስልክ ቁጥር</th>

                      <th style="text-align: left">የብር መጠን</th>
                    </tr>
                    <tr v-for="(cashier, i) in ljoch" :key="i">
                      <td>
                        {{ i + 1 }}
                      </td>
                      <td>
                        {{ cashier.ksm }}
                      </td>
                      <td>
                        {{ cashier.asm }}
                      </td>
                      <td>
                        {{ cashier.phone }}
                      </td>
                      <td>
                        {{ cashier.amount }}
                      </td>
                    </tr>
                  </table>

                  <div style="margin-top: 50px; text-align: center">
                    <v-flex xs8 class="text-left pl-4">
                      <strong>ጠቅላላ የተመዘገበ {{ ljoch.length }} ሰው</strong><br />

                      <strong>ጠቅላላ {{ totalAmount }} ብር</strong><br /><br />
                      <span v-for="fre in frequency" :key="fre.amount">
                        ባለ {{ fre.amount }} ብር&nbsp;&nbsp;&nbsp;&nbsp;{{
                          fre.frequency
                        }}&nbsp;ሰው<br />
                      </span>
                    </v-flex>
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    class="text-capitalize"
                    dark
                    color="red"
                    text
                    @click="printDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="red--text text-center mt-15" v-else>
        <h3>Please complete your payment!</h3>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HorizontalScroll from "vue-horizontal-scroll";
import "vue-horizontal-scroll/dist/vue-horizontal-scroll.css";

export default {
  components: {
    HorizontalScroll,
  },
  data() {
    return {
      addDialog: false,
      ksm: "",
      asm: "",
      phone: "",
      amount: "500",
      user_id: "",
      frequency: [],
      searched: "",
      regLoading: false,
      pageLoading: false,
      ljoch: [],
      filteredLjoch: [],
      totalAmount: 0,
      todayAmount: 0,
      stakes: [],
      placeStakes: [],
      dailyBirr: 0,
      gameId: "",
      ticketNumber: "No Selection",
      stake: [],
      totalPayout: 0,
      oddValue: [],
      selection: [],
      cashier: "Cashier-5",
      printDialog: false,
      posion: "",
      totalStake: 0,

      totalMoey: [],
      totalWin: [],
      isRenewal: false,
      notPaid: 0,
    };
  },

  methods: {
    printList() {
      this.printDialog = true;
      setTimeout(
        function () {
          this.localCopy();
        }.bind(this),
        140
      );
    },

    localCopy() {
      var content = this.$refs.printTbl;
      var newWindow = window.open("");

      newWindow.document.write(content.outerHTML);
      setTimeout(function () {
        newWindow.print();
        newWindow.close();
      }, 140);

      this.printDialog = false;
    },

    getSearched() {
      this.filteredLjoch = [];
      for (let j = 0; j < this.ljoch.length; j++) {
        if (
          this.ljoch[j].ksm == this.searched ||
          this.ljoch[j].asm == this.searched ||
          this.ljoch[j].phone == this.searched ||
          this.ljoch[j].amount == this.searched
        ) {
          this.filteredLjoch.push(this.ljoch[j]);
        }
      }
      if (this.searched == "") {
        for (let j = 0; j < this.ljoch.length; j++) {
          if (j <= 50) {
            this.filteredLjoch.push(this.ljoch[j]);
          }
        }
      }
    },
    async logout() {
      this.$router.push({ name: "login" });
      this.$store.dispatch("loginCashier", "");
    },
    async addLj() {
      if (
        this.asm != "" &&
        this.ksm != "" &&
        this.phone != "" &&
        this.amount != ""
      ) {
        if (this.user_id == "") {
          try {
            var addResult = await axios({
              method: "POST",
              url: this.$store.state.URL,

              data: {
                query: `mutation{
                addNshaLij(
                  ksm:"${this.ksm}",asm:"${this.asm}",
                phone:"${this.phone}",amount:${this.amount},
                ){
                 statusMessage
                 
              }
              
              }
              `,
              },
            });

            if (
              addResult.data.data.addNshaLij[0].statusMessage.toString() ==
              "true"
            ) {
              this.asm = "";
              this.ksm = "";
              this.phone = "";
              this.addDialog = false;
              await this.getLjoch();
            } else alert("አልተሳካም፣ እባክዎ በድጋሜ ይሞክሩ");
          } catch (err) {
            alert(err);
          }
        } else if (this.isRenewal == false) {
          try {
            var updateResult = await axios({
              method: "POST",
              url: this.$store.state.URL,

              data: {
                query: `mutation{
                  updateNshaLij(
                  user_id:${parseInt(this.user_id)},
                  ksm:"${this.ksm}",asm:"${this.asm}",
                phone:"${this.phone}",amount:${this.amount},
                ){
                 statusMessage
                 
              }
              
              }
              `,
              },
            });

            if (
              updateResult.data.data.updateNshaLij[0].statusMessage.toString() ==
              "true"
            ) {
              this.asm = "";
              this.ksm = "";
              this.phone = "";
              this.amount = "";
              this.user_id = "";
              this.addDialog = false;

              await this.getLjoch();
            } else alert("አልተሳካም፣ እባክዎ በድጋሜ ይሞክሩ");
          } catch (err) {
            alert(err);
          }
        } else {
          try {
            var updateResult = await axios({
              method: "POST",
              url: this.$store.state.URL,

              data: {
                query: `mutation{
                  updateNshaLijRenewal(
                  user_id:${parseInt(this.user_id)},
                  ksm:"${this.ksm}",asm:"${this.asm}",
                phone:"${this.phone}",amount:${this.amount},
                ){
                 statusMessage
                 
              }
              
              }
              `,
              },
            });

            if (
              updateResult.data.data.updateNshaLijRenewal[0].statusMessage.toString() ==
              "true"
            ) {
              this.asm = "";
              this.ksm = "";
              this.phone = "";
              this.amount = "";
              this.user_id = "";
              this.addDialog = false;

              await this.getLjoch();
            } else alert("አልተሳካም፣ እባክዎ በድጋሜ ይሞክሩ");
          } catch (err) {
            alert(err);
          }
        }

        this.regLoading = false;
      } else alert("እባክዎ መረጃውን ይሙሉ");
    },

    editClicked(cashier) {
      this.isRenewal = false;
      this.asm = cashier.asm;
      this.ksm = cashier.ksm;
      this.phone = cashier.phone;
      this.amount = cashier.amount;
      this.user_id = cashier.user_id;
      this.addDialog = true;
    },

    renew(cashier) {
      this.isRenewal = true;

      this.asm = cashier.asm;
      this.ksm = cashier.ksm;
      this.phone = cashier.phone;
      this.amount = cashier.amount;
      this.user_id = cashier.user_id;
      this.addDialog = true;
    },

    async deleteCashier(cashier) {
      const deleteConfirm = confirm("Are you sure you want to delete ?");
      if (deleteConfirm) {
        try {
          var deleteCashierResult = await axios({
            method: "POST",
            url: this.$store.state.URL,

            data: {
              query: `mutation{
                deleteLj(
                  user_id:${cashier}){
                 statusMessage
              }
              
              }
              `,
            },
          });
          if (
            deleteCashierResult.data.data.deleteLj[0].statusMessage.toString() ==
            "true"
          ) {
            this.getLjoch();
          } else alert("not deleted, Something went wrong");
        } catch (err) {
          alert(err);
        }
      }
    },

    listAll() {
      this.filteredLjoch = this.ljoch;
    },

    async getLjoch() {
      this.pageLoading = true;

      try {
        var ljResult = await axios({
          method: "POST",
          url: this.$store.state.URL,

          data: {
            query: `{
              getLjoch{
                user_id	
                ksm
                asm
                phone
                amount
                reg_date	
              }
              }
              `,
          },
        });
        this.ljoch = ljResult.data.data.getLjoch;
        this.filteredLjoch = [];
        this.todayAmount = 0;
        this.totalAmount = 0;
        var freq = [];
        this.notPaid = 0;

        for (let j = 0; j < this.ljoch.length; j++) {
          if (j <= 50) {
            this.filteredLjoch.push(this.ljoch[j]);
          }
          this.totalAmount = this.totalAmount + this.ljoch[j].amount;
          if (this.ljoch[j].amount == 0) this.notPaid++;
          freq.push(this.ljoch[j].amount);
          if (
            Number(
              new Date(this.formatDate(new Date(Date.now()))).getTime() -
                9688995
            ) <= Number(this.ljoch[j].reg_date)
          ) {
            this.todayAmount = this.todayAmount + this.ljoch[j].amount;
          }
        }

        let uniqueInputs = [...new Set(freq)];
        this.frequency = [];
        for (let f = 0; f < uniqueInputs.length; f++) {
          var inputCounter = 0;

          for (let k = 0; k < freq.length; k++) {
            if (uniqueInputs[f] == freq[k]) {
              inputCounter = inputCounter + 1;
            }
          }
          this.frequency.push({
            amount: uniqueInputs[f],
            frequency: inputCounter,
          });
        }
      } catch (err) {}

      this.pageLoading = false;
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },

  async created() {
    if (this.$store.state.cashier != "") {
      this.getLjoch();
    } else this.$router.push({ name: "login" });
  },
};
</script>
<style scoped>
#error {
  max-width: 300px;
  margin: auto;
}

#dashboard {
  max-width: 1200px;
  margin: auto;
}
#report {
  max-width: 1000px;
  margin: auto;

  margin-top: 10px;
}

#chooseDate {
  max-width: 400px;
  margin: auto;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 7px;
  padding-right: 40px;
}

tr:nth-child(even) {
  background-color: #faf5f5;
}

.horizontal-scroll {
  scrollbar-width: initial;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}
</style>
